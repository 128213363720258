<!--Módulo de Gestión de Avisos -->

<!-- Avisos Expendientes

funescom -> expediente -> avisos

fnt:        num, get, set
tip:         *get      1=avisos incidencias,2=recep.documentos
std:        *get      0,1 Pendientes, 2=Vistos
id:           *set       Id del aviso
txt:         *set       Texto respuesta observaciones
env:       *set       Envio Respuesta 0=no,1=email,2=sms
ema       *set       Email para envio
sms        *set       Telefono para sms -->

<template>
  <div class="avisosExpedientes" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">
          <v-sheet v-if="!viewMtoOnly" :elevation="4">

            <!-- Cabecera -->
            <div class="cabecera" v-if="!viewMtoOnly" >
              <baseHeader
                :cfg="$cfe.headers.finder"
                :titulo='$store.state[storeName].titulo'
                @onEvent="cerrar">
              </baseHeader>
            </div>

            <!-- Contenido -->
            <div class="contenedor" style="width:1200px">

              <!-- Columna Listado -->
              <div class="conflex">
                <div class="columna" style="width:70%">
                  <div class="conflex">
                    <v-select
                      style="width:29%"
                      v-bind="$select"
                      v-model="ct.estado[2]"
                      :label="ct.estado[1]"
                      :items="itemsEstados"
                      item-value="id"
                      item-text="name"
                      @change="changeEstado">
                    </v-select>

                    <uiText
                      style="width:49%;margin-left:5px"
                      v-bind="$input"
                      v-model="ct.txt[2]"
                      :label="ct.txt[1]"
                      append-icon="mdi-magnify"
                      @Enter="filtrar(ct.txt[2])"
                      @click:append="filtrar(ct.txt[2])">
                    </uiText>

                    <div v-if="ndep>=6" style="width:20%;margin-left:5px">
                      <v-select
                        v-bind="$select"
                        v-model="ct.tipo[2]"
                        label="Tipo"
                        :items="itemsTipo"
                        item-value="id"
                        item-text="name"
                        @change="filtrar(ct.txt[2])">
                      </v-select>
                    </div>
                  </div>

                  <v-sheet v-bind="$cfg.styles.marco">

                    <!-- Grid -->
                    <baseGridMD
                      :store="storeName"
                      :headers="headerGrid"
                      :items-per-page="100"
                      fixed-header
                      show-select
                      dense
                      height="520px"
                      @onEvent="execAccion">

                        <!-- Slot botonera fallename -->
                        <template v-slot:fallename="{ item }">
                          {{ item.fallename }}
                          <div class="light-blue--text text--darken-3"> {{ item.lugar }}</div>
                        </template>

                        <template v-slot:apd="{ item }">
                          <!-- {{ `${Sitem.apdjz}` }}                                     -->
                          <div class="black--text text--darken-1"> {{ item.apdjz }}</div>
                          <div class="light-blue--text text--darken-2"> {{ item.obs }}</div>
                        </template>

                    </baseGridMD>
                  </v-sheet>
                </div>

                <div class="columna" style="width:29%;margin-left:10px">
                  <avisosExpedientesM
                    sync
                    syncUpdate
                    :storeRaiz="storeName"
                    :masterStore="storeName">
                  </avisosExpedientesM>
                </div>
              </div>
            </div>
          </v-sheet>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import dualTemplate from "@/components/dualTemplate";
  import avisosExpedientesM from "@/components/avisosExpedientesM";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, dualTemplate, avisosExpedientesM },
    props: {},

    data() {
      return {
        stIni: {
          api: "avisosExpedientesF",
          titulo:"·Incidencias CTD / COS / APDS",
          name:"avisosExpedientesF",
          mView:'',
          pView:[]
        },

        itemsEstados: [],
        itemsTipo: []
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Fecha", value: "fh", witdh: "10%", filtro:"fechahr" },
            { text: "Control", value: "control", witdh: "10%" },
            { text: "Fallecido / Lugar", value: "fallename", witdh: "10%", slot:true },
            { text: "Jzona-Apd / Incidencia", value: "apd", witdh: "10%", slot:true }
          ]}
        ];

        this.headerGrid= this.headerSet();

        // obtengo datos de GRID
        this.autoLoad= 'buscar';

        // array de estados de los avisos
        this.itemsEstados= [
          { id:"1", name:'Pendientes' },
          { id:"2", name:'Vistos / OK' }
        ];

        // array de tipos de avisos
        this.itemsTipo= [
          { id:"0", name:'(Todos)' },
          { id:"1", name:'CTD' },
          { id:"2", name:'ADMON' }
        ];
      },


      //
      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'get', { tip:1, std:'0' }, "avisos"];
      },


      // (override). apiCall recordsGet
      async stRecordsGet(record, busqueda) {
        // apiArg: llamada API definida en el particular.
        // args: argumentos a fusionar con los definidos en 'args' de apiArgs

        let param = { apiArg: this.apiArgs.get, args: { std:this.ct.estado[2], ctrls: record, accion: busqueda } };
        console.log("stRecordsGet *************************:", param);
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore });
      },


      // entra al cambiar el estado
      // apiCall para obtener los registros según el estado seleccionado
      // filtrado de registros según otros campos (txt, tipo)
      async changeEstado() {
        await this.recordsGet();
        this.filtrar(this.ct.txt[2]);
      },


      // filtrar registros por tipo de documento
      async filtrar(txt) {
        await this.stFiltrar(this.filtrarRecords, { txt });
      },

      //
      filtrarRecords(records, params) {
        return records.filter(elem=> {
          return ((this.ct.tipo[2]== 0? elem : elem.tipo== this.ct.tipo[2]) &&
                 (params.txt?
                 (elem.control.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.fallename.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.lugar.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.apd.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.jz.toLowerCase().includes(params.txt.toLowerCase())) : true));
        });
      },


      //
      cerrar() {
        this.$emit('aviso', { accion:null });

        // obtengo los avisos de expedientes
        if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();
      }

    },


    computed: {
      sch() {
        return {
          estado: [ 'estado', 'Estado', '1' ],
          txt: [ 'txt', 'Busqueda', '' ],
          tipo: [ 'tipo', '', '0' ]
        }
      }
    },

  };
</script>
